import React from "react";
import "./Floatingcta.css";

const Floatingcta = () => {
  const phone = "6285747281466"; // Nomor telepon
  const baseUrl = `https://api.whatsapp.com/send?phone=${phone}&text=Halo%20Kak%20Linda%20https://apps.bimbelmatrix.com/,%20saya%20ingin%20bertanya%20program%20belajar%20untuk:%0A`;

  const message = `
Kelas: [Isi Kelas]
Mapel: [Isi Mapel]
Kurikulum: [Isi Kurikulum]
Wilayah: [Isi Wilayah]
`.trim(); // Placeholder untuk pesan yang diinginkan

  const finalUrl = `${baseUrl}${encodeURIComponent(message)}`;
  return (
    <div className="box-float-main">
      <div className="main-parent-wa">
        <label className="label-cta" htmlFor="">
          Chat With Us
        </label>
        <a
          className="parent-btn-wa"
          href={finalUrl}
          target="_blank"
          rel="noopener noreferrer">
          <button className="btn-float-wa">
            <span className="parent-icon-wa">
              <img src="/images/icon-wa.svg" alt="" className="icon-wa" />
            </span>
          </button>
        </a>
      </div>
    </div>
  );
};

export default Floatingcta;
